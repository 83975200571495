import React, { useState } from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

export const GlobalSearch = () => {
  const [searchValue, setSearchValue] = useState('')
  const history = useHistory()

  const handleSearch = () => {
    if (searchValue.trim()) {
      history.push(`/search?query=${encodeURIComponent(searchValue)}`)
    }
  }

  const handleSubmit = e => {
    e.preventDefault() // Prevents page reload
    handleSearch() // Call your search function
  }

  return (
    <Box sx={{ ml: 2 }}>
      <form className='search-container add-dnr-search' onSubmit={handleSubmit}>
        <input
          name='searchValue'
          className='search-input'
          type='text'
          placeholder='Search'
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
        <img
          src={'/assets/images/search-icon.svg'}
          id='basic-button'
          className='cursor-pointer'
          alt='search-icon'
          onClick={handleSubmit}
        />
      </form>
    </Box>
  )
}
